.about-page {
  max-width: 800px;
  padding-top: 20px;
}

.bio-section {
  margin-bottom: 8px;
}

.bio-text {
  color: #333;
  margin-bottom: 16px;
  line-height: 1.6;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  border: solid 1px #ddd;
  text-decoration: none;
  color: #666;
  transition: background-color 0.2s;
}

.social-link:hover {
  background-color: #e5e5e5;
}

.social-link svg {
  font-size: 1em;
}

.social-link span {
  font-size: 0.9em;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.view-all {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.cards-container {
  display: flex;
  gap: 20px;
  padding: 0 16px 16px 16px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  margin: 0 -12px;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

.carousel-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0 0 150px;
}

.tile-image {
  width: 300px;
  height: 200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/human-60275.appspot.com/o/util%2Fdefault-hobby-cover.png?alt=media&token=812390f8-a4e0-4a22-aa8e-150e6878c8af');
  background-size: cover;
  background-position: center;
}

.tile-title {
  color: #333;
  text-align: left;
}

.see-more-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5 !important;
  cursor: pointer;
}

.see-more-text {
  color: #666;
  font-size: 1.1em;
}

.see-more:hover .see-more-tile {
  background: #eaeaea !important;
}

.signup-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 7000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.banner-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.banner-content p {
  margin: 0;
  font-size: 14px;
}

.cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.cta-button:hover {
  background-color: #0069d9;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #6c757d;
  padding: 0 5px;
}

.close-button:hover {
  color: #343a40;
}
