.hobby-category-circle {
    width: 30px; /* Circle size */
    height: 30px; /* Circle size */
    border-radius: 50%; /* Makes the div circular */
  }

  .hobbies-list {
    margin-bottom: 95px;
  }
  
  .hobby-row {
    align-items: center;
    border-bottom: 1px solid #DEDEE4;
    padding: 16px 0;
  }
    .hobby-row.selected {
        font-weight: bold;
    }


.hobby-list-short {
    max-height: 20vh;
    overflow: scroll;
    border-bottom: solid 1px #DEDEE4;
  }
    .hobby-list-short .hobby-row {
      width: 100%;
      border-bottom: none;
      padding: 0px;
      padding-bottom: 24px;
    }
  
  .icon-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
  }
  
  .hobby-icon {
    color: #a0a0a0; /* Adjust the color as per your design */
  }

  .text-col {
    padding-left: 0px;
  }
  
  .text-col .post-count {
    color: #95969A; /* Adjust the color as per your design */
  }

.hobby-header {
    color: #fff;
    padding-top: 16px;
    margin-top: 47px;
}
    .black-gradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0, 0.8) 100%);
    }
    .black-gradient h1 {
        padding-top: 160px;
        margin-bottom: 0px;
    }
    .black-gradient p {
        margin-top: 0px;
        font-size: 0.9em;
    }

.stat-highlights .stat {
    padding-top: 24px;
    padding-bottom: 8px;
    text-align: center;
}
    .stat-highlights .stat .stat-number {
        font-size: 1.5em;
        font-weight: 500;
        color: #333;
    }
    .stat-highlights .stat .stat-label {
        font-size: 0.95em;
        color: #a0a0a0;
    }

.about-hobby-top img {
    border-radius: 20px;
    margin: 16px 0px;
}

.about-hobby-top span {
    color: #a0a0a0;
    display: inline-block;
    margin-top: 24px;
    margin-bottom: 0px;
}

.about-hobby-top h2 {
    padding-bottom: 8px;
}

.hobby-friend-list {
    margin-bottom: 100px;
    margin-top: 24px;
}
    .hobby-friend-list h3 {
        /* padding-bottom: 16px; */
    }

    .hobby-friend-list .friend {
        border-bottom: solid 1px #DEDEE4;
        margin-top: 16px;
    }

.hobby-card {
  /* margin-bottom: 1rem; */
  cursor: pointer;
  color: #fff;
  min-height: 150px;
  position: relative;
}

.hobby-card {
  
}
/* 
.hobby-card .black-gradient {
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 100%);
  padding: 1rem;
} */

.hobby-card h2 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  padding-top: 100px;
}

.hobby-card p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.25rem;
}

.hobby-card .post-count-label {
  color: rgba(255, 255, 255);
  font-weight: 600;
  font-size: 0.8em;
  display: inline-block;
  margin-top: 16px;
  padding: 4px 8px;
  border-radius: 20px;
  background: rgba(41, 41, 41, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* border: 1px solid rgba(206, 206, 206, 0.3); */
}

.hobby-card .right-align {
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding-top: 90px;
}

.page-container {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
}

.content-container {
    position: relative;
    min-height: calc(100vh - [height-of-your-header]);  /* Adjust this value based on your header height */
    overflow: hidden;
}

/* Custom Modal Styles */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.custom-modal-content {
  background-color: white;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
}

.custom-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.custom-modal-body {
  padding: 15px;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
  border-top: 1px solid #dee2e6;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}